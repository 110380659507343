<template>
    <div class="content" v-bind:style="{ backgroundImage: backgroundImage() }">
        <div class="logo">
            <img v-bind:src="logo" alt="Gingham Jelly Company" width="350" />
        </div>
        <div class="message">
            <h1 v-bind:class="color">Coming Soon</h1>
            <h3><a v-bind:class="color" href="email:sales@ginghamjelly.com">sales@ginghamjelly.com</a></h3>
        </div>
    </div>
</template>

<script>

let colors = ['red','blue','orange'];
let rnd = (Math.floor(Math.random()*(colors.length-1+1))+1)-1;
let randomColor = colors[rnd];

export default {
    name: 'ComingSoon',
    data: function() {
        return {
            color: randomColor
        };
    },
    computed: {
        logo: () => {
            return require('../assets/logo_' + randomColor + '.png');
        }
    },
    methods: {
        backgroundImage() {
            return "url('" + require('../assets/gingham_' + randomColor + '_45.png') + "')";
        }
    }
}
</script>

<style scoped>
.content {
    background-image: url('../assets/gingham_red_45.png');
    background-repeat: repeat;
    width: 100%;
    height: 100vh;
}
.logo {
    padding-top: 10%;
}

a {
    text-decoration: none;
}
.red {
    color: #ff0000;
}
.blue {
    color: #0594a7;
}
.orange {
    color: #ff7300;
}
</style>

<style lang="scss" scoped>
@import "~sass-text-stroke/_text-stroke";

$size: 5;
$color: #ffffff;
$correction: 0;

h1 {
    @include text-stroke($size, $color, $correction);
}
a {
    @include text-stroke(4, $color);  
}
</style>