<template>
  <div class="home">
    <ComingSoon />
  </div>
</template>

<script>
import ComingSoon from '@/components/ComingSoon.vue'

export default {
    name: 'Home',
    components: {
      ComingSoon
  }
}
</script>
